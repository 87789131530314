import * as React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerSection from "../components/nftexchange/Banner"
import Whatis from "../components/nftexchange/Whatis"
import WhyShould from "../components/nftexchange/WhyShould"
import TopFeatures from "../components/nftexchange/TopFeatures"
import HowOur from "../components/nftexchange/HowOur"
import CoreFeatures from "../components/nftexchange/CoreFeatures"
import SecurityofOur from "../components/nftexchange/SecurityofOur"
import UsecaseOf from "../components/nftexchange/UsecaseOf"
import WhyChoose from "../components/nftexchange/WhyChoose"
import Testimonial from "../components/whitelabelpage/Testimonial"
import Review from "../components/Landing/Review"
import DevApproach from "../components/nftexchange/DevApproach"
import TechStack from "../components/nftexchange/TechStack"
import FaqSection from "../components/nftexchange/FaqSection"
import PortfolioSection from "../components/nftexchange/Portfolio"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"


const nftexchange = () => (
  <section className="dev-page">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>NFT Exchange Development Company - Coinsclone</title>
        <meta name="description" content="Coinsclone - Best NFT Exchange Development Company offers excellent services to develop a full-fledged NFT Exchange Platform for smooth NFT Trading Experiences." />
        <meta name="keywords" content="NFT Marketplace Development Services, NFT Exchange Platform Development Company, NFT art Marketplace Development, NFT Exchange Development Company, White Label NFT Trading Platform Development" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="NFT Exchange Development Company - Coinsclone" />
        <meta property="og:description" content="Coinsclone - Best NFT Exchange Development Company offers excellent services to develop a full-fledged NFT Exchange Platform for smooth NFT Trading Experiences." />
        <meta property="og:url" content="https://www.coinsclone.com/nft-exchange-development/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/nft-exchange-development.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone - Best NFT Exchange Development Company offers excellent services to develop a full-fledged NFT Exchange Platform for smooth NFT Trading Experiences." />
        <meta name="twitter:title" content="NFT Exchange Development Company - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/nft-exchange-development.png" />
        <link rel="canonical" href="https://www.coinsclone.com/nft-exchange-development/" />
      </Helmet>
      <BannerSection />
      <div className="breadcrumb mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | NFT Exchange Development Company</span>
        </div>
      </div>
      <Whatis />
      <WhyShould />
      <TopFeatures />
      <HowOur />
      <CoreFeatures />
      <SecurityofOur />
      <UsecaseOf />
      <WhyChoose />
      <Testimonial />
      <Review />
      <PortfolioSection />
      <DevApproach />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default nftexchange
