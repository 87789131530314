import React from 'react'
import ButtonStartfree from '../ButtonComponent/ButtonStartfree'


class BannerSection extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">NFT Exchange Development Company</h1>
            <p className="sub-heading">Coinsclone is a leading NFT Exchange development company, specializing in creating robust NFT platforms for all your NFT business.</p>
            <p className="sub-small">We bring creators and investors together in an ecosystem, fostering growth and innovation in the NFT Marketplace.</p>
          </div>
          <div className="quick text-center">
            <img width="983px" height="579px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/nft-exchange-development.png" alt="NFT Exchange Development Company" />
          </div>
          <p className="pharagraph">We are a reliable, leading NFT exchange development company with an unbeatable track record.
          </p>
          <div className="text-center mt-4">
            <ButtonStartfree />
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection