import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq">

      <Container>
        <div className="text-center">
          <h4 className="heading-h2"><span className="heading-h3"><span className="bluecolor">FAQ</span>  </span>
            Frequently Asked Questions
          </h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                Is setting up an NFT Exchange a guaranteed form of making money?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Every business is not a guaranteed source of income, and the same goes for the NFT Exchange platform. By accomplishing core functions and functionalities, you can draw an ample number of audience to it. One has to encourage as many people as possible to trade using your exchange, for you to make good money.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                Can I use multi-currency wallets in the NFT Exchange that you will develop?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Yes, we can incorporate multi-cryptocurrency wallets with robust security features and smooth transaction capabilities. We support you with the best in our NFT Trading platform development.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                What tech stacks are used for the NFT Exchange platform development?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">
                  <p>The tech stack used for NFT Exchange developments is as follows,</p>
                  <ul>
                    <li>For Frontend development - Next.js, Bootstrap, React.</li>
                    <li>For Backend development - PHP, Python, Django.</li>
                    <li>For Blockchain development - Rust, Solidity, Vyper.</li>
                    <li>For Data Storage - GraphQL, PostgreSQL, MongoDB.</li>
                  </ul>
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                How long does it take to build an NFT Exchange Platform?
                </Accordion.Toggle>

              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">To develop an NFT Exchange platform it may take around 6 months to 1 year. And for the complex customization and add-ons, the time may increase further.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                What is the cost of building an NFT Exchange development?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">The approximate cost to deploy an NFT Exchange platform development starts from $15,000. Depending upon the customization, and add-on features, the cost may vary slightly.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection